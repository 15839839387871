import {FondyService} from "../domain";
import {Api} from 'ipsp-js-sdk';
import {injectable} from "inversify";
import {ok, err, Result} from "neverthrow";

@injectable()
export class JsFondyService implements FondyService {
  private readonly fondyApi: any;

  constructor() {
    this.fondyApi = new Api();
  }

  async verifyCard(cardNumber: number, exp: string, cvv: number, token: string): Promise<Result<any, string>> {
    const request = new Promise((resolve, reject) => {
      this.fondyApi.scope(() => {
        this.fondyApi.request('api.checkout.form', 'request', {
          payment_system: 'card',
          token: token,
          card_number: cardNumber,
          expiry_date: exp,
          cvv2: cvv,
        }).done(model => {
          resolve(model);
        }).fail(model => {
          reject(model.attr('error').message);
        });
      });
    });

    try {
      const response = await request;
      return ok(response);
    } catch (e) {
      return err(e);
    }
  }

}
